import t from './localization.js';
import { bigcommerceAppClientId } from '../_common/constants.js';

window.Convermax.t = t;

const AutoSyncKey = 'CWTq2EZjCogVAcaPl9WJ';

const baseFitmentFields = ['Year', 'Make', 'Model', 'Engine'];
const extraFitmentFields = [
  'QualifierText',
  'BedLength',
  'BodyDoor',
  'BodyType',
  'Drive',
  'BrakeSystem',
  'BrakeAbs',
  'EngineAspiration',
  'EngineDesignation',
  'EngineVersion',
  'EngineVin',
  'FrontBrakeType',
  'RearBrakeType',
  'FrontSpringType',
  'RearSpringType',
  'SteeringSystem',
  'SteeringType',
  'MfrBodyCode',
  'Transmission',
];
const wheelsFields = ['wheel_diameter', 'wheel_width', 'wheel_bolt_pattern'];
const tiresFields = ['tire_width', 'tire_aspect', 'tire_rim'];
const ignoreFields = [...wheelsFields, ...tiresFields];

const categorySelectionPageUrl = '/produktkategorien/';
const brandSelectionPageUrl = '/brands/';
const genericVehiclePageUrl = '/vehicle/';

const isVehicleLandingPage = window.location.pathname.startsWith(genericVehiclePageUrl);

window.Convermax.showFitmentTable = () =>
  window.document.querySelector('a[href="#tab-fitment-details"]').click();

window.Convermax.handleWishlistDropdown = (e) => {
  const wishlistDropdownForm = e.currentTarget.parentElement;
  const wishlistDropdown = e.currentTarget.parentElement.querySelector('.dropdown-menu');

  if (wishlistDropdownForm) {
    wishlistDropdownForm.classList.toggle('is-open');
  }

  if (wishlistDropdown) {
    wishlistDropdown.classList.toggle('is-open');

    // handle click on document, close opened dropdown
    if (wishlistDropdown.classList.contains('is-open')) {
      const handleClickOnDocument = (event) => {
        if (!wishlistDropdownForm.contains(event.target)) {
          wishlistDropdownForm.classList.remove('is-open');
          wishlistDropdown.classList.remove('is-open');
          document.removeEventListener('click', handleClickOnDocument);

          if (window.innerWidth <= 1000) {
            document.removeEventListener('touchmove', handleClickOnDocument);
          }
        }
      };

      document.addEventListener('click', handleClickOnDocument);

      if (window.innerWidth <= 1000) {
        document.addEventListener('touchmove', handleClickOnDocument);
      }
    }
  }
};

function ensureContextIsSet(getContext, timeout) {
  const start = Date.now();
  return new Promise(waitForContext);

  function waitForContext(resolve, reject) {
    const context = getContext();
    if (context) {
      resolve(context);
    } else if (timeout && Date.now() - start >= timeout) {
      reject(() => null);
    } else {
      setTimeout(waitForContext.bind(this, resolve, reject), 30);
    }
  }
}

const getLang = () => localStorage.getItem('cm_lang') || 'de';

Convermax.getLang = getLang;

function updateCallback() {
  window.document.body.classList.add(`cm_${getLang()}`);

  const pagination = window.document.querySelector('.cm_SearchHeader .cm_pagination');

  // handle case, when SearchHeader doesn't contain pagination
  window.document
    .querySelector('.cm_SearchHeader .cm_sort')
    ?.classList.toggle('without-pagination', !pagination);
}

function AfterInit() {
  // handle language switch for localization
  // this is a very serious crutch
  // ajax is much better than an extra reboot, in fact we broke good behavior,
  // our code cannot selectively relerate when changing Window.
  // pieces of code inside the templates depending on the change of Window are not redrawn when it is changed.
  // therefore, we clearly update the page when switching the language

  ensureContextIsSet(() => window.Weglot.initialized, 10000).then(function () {
    window.Weglot.on('languageChanged', changeLang);
    changeLang(window.Weglot.getCurrentLang(), '');
  });

  function changeLang(newLang, prevLang) {
    const usedLang = getLang();

    if (usedLang !== newLang) {
      localStorage.setItem('cm_lang', newLang);
      setTimeout(() => window.location.reload(), 1000);
    }
  }
}

function getLocalPreselection(pageType, defaults) {
  if (window.location.pathname.startsWith('/sales')) {
    return [{ field: 'on_sale', term: 'True' }];
  }

  return defaults.getter(pageType);
}

globalThis.Convermax.handleProductCompareClick = () => {
  const productsIdsToCompare = [...window.document.querySelectorAll('input[name="products[]"]:checked')].map(
    (product) => product.dataset.compareId,
  );

  const compareButton = window.document.body.querySelector('.cm_card-compare');

  if (!compareButton) {
    return;
  }

  const compareLink = window.document.body.querySelector('.cm_card-compare_link');
  const compareCount = window.document.body.querySelector('.cm_card-compare_count');

  compareCount.textContent = productsIdsToCompare.length;
  compareButton.classList.toggle('show', productsIdsToCompare.length > 1);
  compareLink.href = `/compare/${productsIdsToCompare.join('/')}/`;
};

globalThis.Convermax.handleCompareResetClick = () => {
  const productCheckboxes = window.document.querySelectorAll('input[name="products[]"]:checked');
  productCheckboxes.forEach((checkbox) => {
    checkbox.checked = false;
  });
  globalThis.Convermax.handleProductCompareClick();
};

globalThis.Convermax.isLoggedIn = window.document.querySelector(
  '.header-navigation__account #account-dropdown',
);

const customerGroupId = getGroupId();
async function getGroupId() {
  if (!window.Convermax?.isLoggedIn) {
    return '';
  }
  const groupId = await new Promise((resolve) => {
    const xmlHttp = new XMLHttpRequest();
    xmlHttp.onreadystatechange = function () {
      if (xmlHttp.readyState === 4) {
        if (xmlHttp.status === 200) {
          const jwt = xmlHttp.responseText;
          const [, jsonPart] = jwt.split('.');
          const { customer } = JSON.parse(atob(jsonPart));
          resolve(customer.group_id);
        } else if (xmlHttp.status === 404) {
          resolve(null);
        } else {
          resolve(null);
        }
      }
    };
    xmlHttp.open('GET', `/customer/current.jwt?app_client_id=${bigcommerceAppClientId}`, true);
    xmlHttp.send();
  });
  return groupId || '';
}

export default {
  platform: 'bigcommerce',
  AfterInit,
  pagination: {
    edgeRange: 2,
    centralRange: 1,
  },
  SearchRequestDefaults: {
    pageSize: 24,
    extra: {
      country: window.Convermax.country,
      lang: getLang(),
      customerGroupId,
    },
    sort: window.document.querySelector('#cm_results.cm_new-products')
      ? 'pdm_date_created:desc'
      : 'relevance',
  },
  autocomplete: {
    requestDefaults: {
      extra: {
        country: window.Convermax.country,
        lang: getLang(),
        customerGroupId,
      },
    },
  },
  getLocalPreselection,
  product: {
    priceFormatter: new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }),
  },
  fitmentSearch: {
    baseFields: baseFitmentFields,
    extraFields: extraFitmentFields,
    categorySelectionPages: [
      { pathname: genericVehiclePageUrl, field: 'category' },
      { pathname: categorySelectionPageUrl, field: 'category' },
      { pathname: brandSelectionPageUrl, field: 'brand_name' },
    ],
    getFitmentSearchTitle: () => t('Parts'),
    categorySelectionPageRedirect: true,
    isAutoRedirectDisabled: true,
    hideVehicleFromUrl: isVehicleLandingPage,
  },
  page: {
    getPageType: (defaults) =>
      window.document.querySelector('body.page_type__default')
        ? 'home'
        : window.document.querySelector('body.page_title__search')
          ? 'search'
          : window.location.pathname === genericVehiclePageUrl
            ? 'generic-vehicle'
            : window.document.querySelector('body.page_type__brands') ||
                window.location.pathname === categorySelectionPageUrl
              ? 'parts'
              : window.document.querySelector('body.page_type__category')
                ? 'category'
                : defaults.getter(),
  },
  facets: {
    rangedFacet: [{ fields: ['wheel_bore'], step: '1/10//1', slider: { mode: 'LowerBound' } }],
  },
  Widgets: [
    {
      name: 'SearchPage',
      location: '#cm_results',
      template: 'SearchPage',
    },
    {
      name: 'CategoryPage',
      type: 'SearchPage',
      location: 'body.page_type__category .page',
      template: 'SearchPage',
      visibleIf: () =>
        !window.document.querySelector('.page.vehicle-page') &&
        window.location.pathname !== categorySelectionPageUrl,
    },
    {
      name: 'BrandPage',
      type: 'SearchPage',
      location: 'body.page_type__brand .page-content',
      template: 'SearchPage',
    },

    {
      name: 'SearchVehicleWidget_landingPage',
      type: 'VehicleWidget',
      location: {
        insertBefore: '.page.vehicle-page .top-categories',
      },
      template: 'fitmentSearch/searchVehicleWidgetContainer',
      fields: baseFitmentFields,
      columnBreakpoint: 700,
    },
    {
      name: 'GenericVehicleSearchPage',
      type: 'SearchPage',
      location: {
        lastChildOf: '.page.vehicle-page .recommended-products',
        class: 'cm_generic-vehicle-search-result',
      },
      template: 'MainContent',
    },
    {
      name: 'GenericVehicleCategories',
      type: 'FacetTiles',
      location: '.page.vehicle-page div[data-content-region="vehicle_page_top_categories"]',
      template: 'facetTiles/landingSubtitles',
      facetField: 'category',
    },
    {
      name: 'SearchHeader',
      sortSelectClass: 'form-select',
      sortEntries: {
        'relevance': t('Best Match'),
        ...(getLang() === 'en' && { 'name_en': t('Name: A to Z'), 'name_en:desc': t('Name: Z to A') }),
        ...(getLang() === 'de' && { 'name': t('Name: A to Z'), 'name:desc': t('Name: Z to A') }),
        'price': t('Price: Low to High'),
        'price:desc': t('Price: High to Low'),
      },
    },
    {
      name: 'FacetPanel',
      ignoreFields,
    },
    {
      name: 'CategoryFacet',
      type: 'FacetPanel',
      fields: 'category',
    },
    {
      name: 'Facets',
      type: 'FacetPanel',
      initCollapsed: true,
      ignoreFields,
    },
    {
      name: 'FilterChips',
      type: 'FacetPanel',
      ignoreFields: [...ignoreFields, 'category'],
    },
    {
      name: 'VehicleSpecs',
      type: 'VehicleWidget',
      fields: ['BodyType', 'Transmission', 'FrontBrakeType'],
    },
    {
      name: 'FacetBarWheels',
      type: 'FacetBar',
      fields: wheelsFields,
    },
    {
      name: 'FacetBarTires',
      type: 'FacetBar',
      fields: tiresFields,
    },
    {
      name: 'SearchResult',
      updateCallback,
    },
    {
      name: 'SearchBox',
      location: '.header-navigation__search',
      template: 'SearchBox',
    },
    {
      name: 'SearchBoxMobile',
      type: 'SearchBoxDialogButton',
      location: '.navPages-quickSearch .container',
      template: 'searchBox/dialogButtonAdaptable',
    },
    {
      name: 'HeaderVehicleWidget',
      type: 'VehicleWidget',
      location: '.navPages-list .navPages-item:nth-child(2)',
      template: 'fitmentSearch/collapsibleVehicleWidget',
      initCollapsed: true,
      isAlwaysColumnLayout: true,
      fields: baseFitmentFields,
    },
    {
      name: 'HomeVehicleWidget__desktop',
      type: 'VehicleWidget',
      location: '.car-selection',
      template: 'fitmentSearch/homeVehicleWidget',
      fields: baseFitmentFields,
      columnBreakpoint: 400,
    },
    {
      name: 'SearchVehicleWidget',
      type: 'VehicleWidget',
      fields: baseFitmentFields,
      columnBreakpoint: 700,
    },
    {
      name: 'Garage',
      location: '.header-navigation__garage',
      template: 'fitmentSearch/garage',
    },
    {
      name: 'VerifyFitment__desktop',
      type: 'VerifyFitment',
      location: { firstChildOf: '.desktop-only #tab-description' },
      template: 'fitmentSearch/verifyFitment',
    },
    {
      name: 'VerifyFitment__mobile',
      type: 'VerifyFitment',
      location: { firstChildOf: '.mobile-only #tab-description' },
      template: 'fitmentSearch/verifyFitment',
    },
    {
      name: 'FitmentTableDesktop',
      type: 'FitmentTable',
      location: '.desktop-only #tab-fitment-details',
      template: 'fitmentSearch/fitmentTable',
    },
    {
      name: 'FitmentTableMobile',
      type: 'FitmentTable',
      location: '.mobile-only #tab-fitment-details',
      template: 'fitmentSearch/fitmentTable',
      columnBreakpoint: 700,
    },
    {
      name: 'FacetCategoryTiles',
      type: 'FacetTiles',
      location: 'body.page_type__category .page',
      template: 'facetTiles/hierarchicalContainer',
      visibleIf: () => window.location.pathname === categorySelectionPageUrl,
    },
    {
      name: 'FacetBrandTiles',
      type: 'FacetTiles',
      location: { replace: 'body.page_type__brands .brandGrid' },
      template: 'facetTiles/hierarchicalContainer',
    },
    {
      name: 'AutoSyncVisualization',
      apiKey: AutoSyncKey,
      config: {
        height: '150px',
        vehicleImageSize: 640,
        vehicleAngles: [32],
        showColorPicker: false,
      },
      classModificator: 'search-result',
    },
    {
      name: 'FacetDialog',
      template: 'FacetDialog',
      ignoreFields,
    },
    {
      name: 'SearchBoxDialog',
      template: 'SearchBoxDialog',
    },
    {
      name: 'VehicleWidgetDialog',
      template: 'fitmentSearch/dialog',
      fields: baseFitmentFields,
    },
    {
      name: 'AutoSyncVisualizationDialog',
      apiKey: AutoSyncKey,
      template: 'fitmentSearch/autoSyncVisualizationDialog',
    },
  ],
};
