export default {
  PRODUCT_REPEATER_CLASS: 'productGrid',
  PREV_PAGE: `{Convermax.t('Previous')}`,
  NEXT_PAGE: `{Convermax.t('Next')}`,

  SEARCH_BOX_CLASS: 'form quick-search-bar',
  SEARCH_BOX_INPUT_CLASS: 'form-input input-field',
  SEARCH_BOX_PLACEHOLDER: `{Convermax.t('What are you looking for?')}`,
  SEARCH_BOX_TITLE: `{Convermax.t('Search')}`,
  SEARCH_BOX_CLEAR_BUTTON: `{window.innerWidth > 768 ? '✕' : Convermax.t('Clear')}`,
  BROWSE_ALL: `{Convermax.t('Browse all products')}`,

  FILTERS_TEXT: `{Convermax.t('Filters')} <span rt-if="this.selectedFacetsCount" class="cm_dialogButton__count"> {{selectedFacetsCount}}</span>`,
  FACET_TITLE_ICON: `
    <rt-virtual rt-if="this.isCollapsed" template="icons/angle-down" />
    <rt-virtual rt-if="!this.isCollapsed" template="icons/angle-up" />`,
  FACET_DIALOG_TITLE: `{Convermax.t('Filters:')}`,
  FACET_DIALOG_CLEAR: `{Convermax.t('CLEAR FILTER')}`,
  FACET_DIALOG_DONE: `{Convermax.t('APPLY FILTER & CLOSE')}`,
  FACET_DIALOG_BUTTON_CLASS: `cm_dialogButton form-field selection{this.selectedFacetsCount ? ' selected' : ''}`,

  CURRENT_SEARCH: `{Convermax.t('Current Search:')}`,
  MAX_PRICE: `{Convermax.t('Max Price')}`,
  MIN_PRICE: `{Convermax.t('Min Price')}`,
  SELECTED_CUSTOM_RANGE_TO: `{Convermax.t('to')}`,
  PRICE_FACET_MORE: `{Convermax.t('and more')}`,
  PRICE_FACET_LESS: `{Convermax.t('and less')}`,
  FACET_FILTER_PLACEHOLDER: `{Convermax.t('Enter')}`,
  FACET_FILTER_NOTHING: `{Convermax.t('No entries found')}`,
  SHOW_LESS: `{Convermax.t('Show Less...')}`,
  SHOW_MORE: `{Convermax.t('Show More...')}`,
  START_OVER: `{Convermax.t('Start Over')}`,

  FITMENT_TABLE_MANY_FITMENTS: `{Convermax.t('Currently displaying 1000 fitments, yet the product has additional fitments.')}`,
  FITMENT_TABLE_HORIZONTAL_FIELD: `{Convermax.t(this[field])}`,

  GARAGE_ICON: '<i class="material-icons-outlined">garage</i>',
  GARAGE_TITLE: `<span class="garage-title">{Convermax.t('My')} Garage</span>`,
  CLEAR_GARAGE_TEXT: `{Convermax.t('Clear garage')}`,
  GET_PRODUCTS_FOR_VEHICLE: `{Convermax.t('Get products for your vehicle')}`,
  FIND_PARTS_YOU_NEED: `{Convermax.t('Easily find the parts you need')}`,
  STORE_VEHICLE_IN_GARAGE: `{Convermax.t('Store your vehicle in the garage')}`,

  SELECT_YOUR_VEHICLE: `{this.isVehicleSelected ? Convermax.t('Your vehicle') : Convermax.t('Select your vehicle')}`,
  SELECT_YOUR_VEHICLE_BLOCK: `{this.isVehicleSelected ? Convermax.t('Your vehicle') : Convermax.t('Select your vehicle')}`,
  ADD_VEHICLE: `{Convermax.t('Add a vehicle')}`,
  CLEAR_TEXT: `{Convermax.t('Clear')}`,
  GO_TEXT: `{Convermax.t('GO')}`,
  CHANGE_TEXT: `{Convermax.t('Change')}`,
  CHANGE_VEHICLE: `{Convermax.t('Change')}`,
  DISCARD_VEHICLE: `{Convermax.t('Clear')}`,

  VERIFY_FITMENT_TITLE: `{Convermax.t('Verify fits with your vehicle')}`,
  VERIFY_FITMENT_FITS: `{Convermax.t('This product fit your')}`,
  VERIFY_FITMENT_NOT_FIT: `{Convermax.t('This product DOES NOT fit your')}`,
  VERIFY_FITMENT_SPECIFY: ``,
  FITMENT_UNIVERSAL_TITLE: `{Convermax.t('Universal fit')}`,
  FITMENT_UNIVERSAL_TEXT: `{Convermax.t('This product may require modification.')}`,
  FITMENT_UNKNOWN_TITLE: `{Convermax.t('No Fitment Data')}`,
  FITMENT_UNKNOWN_TEXT: `{Convermax.t('This product doesn"t have fitment application data. Please verify fitment manually.')}`,
  VERIFY_FITMENT_VEHICLE: `{this.vehicleString || Convermax.t('Vehicle')}`,

  SELECT_TITLE: '{Convermax.t(this.title) || this.title}',
  SELECT_LOADING: `{Convermax.t('...loading...')}`,

  VEHICLE_SPECS_TITLE: `{Convermax.t('Vehicle specs')}`,
  VEHICLE_SPECS_RESET: `{Convermax.t('Reset')}`,
};
