import { SearchPage } from 'Components'
import TemplateSearchPage from 'Stores/2w37mf1yxr-1/Templates/SearchPage.rt'
import { VehicleWidget } from 'Components'
import TemplatefitmentSearchsearchVehicleWidgetContainer from 'Stores/_default-store/Templates/fitmentSearch/searchVehicleWidgetContainer.rt'
import TemplateMainContent from 'Stores/2w37mf1yxr-1/Templates/MainContent.rt'
import { FacetTiles } from 'Components'
import TemplatefacetTileslandingSubtitles from 'Stores/2w37mf1yxr-1/Templates/facetTiles/landingSubtitles.rt'
import { SearchHeader } from 'Components'
import { FacetPanel } from 'Components'
import { FacetBar } from 'Components'
import { SearchResult } from 'Components'
import { SearchBox } from 'Components'
import TemplateSearchBox from 'Stores/_default-store/Templates/SearchBox.rt'
import { SearchBoxDialogButton } from 'Components'
import TemplatesearchBoxdialogButtonAdaptable from 'Stores/_default-store/Templates/searchBox/dialogButtonAdaptable.rt'
import TemplatefitmentSearchcollapsibleVehicleWidget from 'Stores/2w37mf1yxr-1/Templates/fitmentSearch/collapsibleVehicleWidget.rt'
import TemplatefitmentSearchhomeVehicleWidget from 'Stores/_default-store/Templates/fitmentSearch/homeVehicleWidget.rt'
import { Garage } from 'Components'
import TemplatefitmentSearchgarage from 'Stores/_default-store/Templates/fitmentSearch/garage.rt'
import { VerifyFitment } from 'Components'
import TemplatefitmentSearchverifyFitment from 'Stores/2w37mf1yxr-1/Templates/fitmentSearch/verifyFitment.rt'
import { FitmentTable } from 'Components'
import TemplatefitmentSearchfitmentTable from 'Stores/_default-store/Templates/fitmentSearch/fitmentTable.rt'
import TemplatefacetTileshierarchicalContainer from 'Stores/_default-store/Templates/facetTiles/hierarchicalContainer.rt'
import { AutoSyncVisualization } from 'Components'
import { FacetDialog } from 'Components'
import TemplateFacetDialog from 'Stores/2w37mf1yxr-1/Templates/FacetDialog.rt'
import { SearchBoxDialog } from 'Components'
import TemplateSearchBoxDialog from 'Stores/_default-store/Templates/SearchBoxDialog.rt'
import { VehicleWidgetDialog } from 'Components'
import TemplatefitmentSearchdialog from 'Stores/_default-store/Templates/fitmentSearch/dialog.rt'
import { AutoSyncVisualizationDialog } from 'Components'
import TemplatefitmentSearchautoSyncVisualizationDialog from 'Stores/_default-store/Templates/fitmentSearch/autoSyncVisualizationDialog.rt'

export const compMap = {
  SearchPage,
VehicleWidget,
FacetTiles,
SearchHeader,
FacetPanel,
FacetBar,
SearchResult,
SearchBox,
SearchBoxDialogButton,
Garage,
VerifyFitment,
FitmentTable,
AutoSyncVisualization,
FacetDialog,
SearchBoxDialog,
VehicleWidgetDialog,
AutoSyncVisualizationDialog
} 
export const tplMap = {
  'SearchPage': TemplateSearchPage,
'fitmentSearch/searchVehicleWidgetContainer': TemplatefitmentSearchsearchVehicleWidgetContainer,
'MainContent': TemplateMainContent,
'facetTiles/landingSubtitles': TemplatefacetTileslandingSubtitles,
'SearchBox': TemplateSearchBox,
'searchBox/dialogButtonAdaptable': TemplatesearchBoxdialogButtonAdaptable,
'fitmentSearch/collapsibleVehicleWidget': TemplatefitmentSearchcollapsibleVehicleWidget,
'fitmentSearch/homeVehicleWidget': TemplatefitmentSearchhomeVehicleWidget,
'fitmentSearch/garage': TemplatefitmentSearchgarage,
'fitmentSearch/verifyFitment': TemplatefitmentSearchverifyFitment,
'fitmentSearch/fitmentTable': TemplatefitmentSearchfitmentTable,
'facetTiles/hierarchicalContainer': TemplatefacetTileshierarchicalContainer,
'FacetDialog': TemplateFacetDialog,
'SearchBoxDialog': TemplateSearchBoxDialog,
'fitmentSearch/dialog': TemplatefitmentSearchdialog,
'fitmentSearch/autoSyncVisualizationDialog': TemplatefitmentSearchautoSyncVisualizationDialog
};